import { render, staticRenderFns } from "./ContactsListPage.vue?vue&type=template&id=6ca2f107&scoped=true"
import script from "./ContactsListPage.vue?vue&type=script&lang=js"
export * from "./ContactsListPage.vue?vue&type=script&lang=js"
import style0 from "./ContactsListPage.vue?vue&type=style&index=0&id=6ca2f107&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ca2f107",
  null
  
)

export default component.exports