<template>
    <div class="contacts-list">
        <StackRouterHeaderBar :show-title="false" />
        <div class="title" v-html="'연락처로 쉽게 초대'"></div>
        <div class="search-box">
            <i class="material-icons search-icon">search</i>
            <input @input="onInput" placeholder="검색" />
        </div>
        <div class="body">
            <div class="row" v-for="contact in contacts" :key="contact.recordID">
                <div class="left">
                    <div class="name">{{ contact.name }}</div>
                    <div class="number">{{ contact.number }}</div>
                </div>
                <div class="right" :class="{ complete: contact.done }" @click="invite(contact)">
                    {{ contact.done ? '초대 완료' : '초대' }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'ContactsListPage',
    components: {},
    data: () => ({
        search: '',
        inviteList: [],
    }),
    watch: {},
    computed: {
        me() {
            return this.$store.getters.me
        },
        contacts() {
            return (this.$store.getters.contacts || [])
                .map(c => {
                    c.number = ((c.phoneNumbers[0] || {}).number || '').replaceAll('-', '').replaceAll('/', '')
                    c.name = (c.familyName || '') + (c.givenName || '')
                    c.done = this.inviteList.includes(c.number)
                    return c
                })
                .filter(c => c.number && (this.search.length === 0 || c.name.includes(this.search)))
                .sort((a, b) => (b.name > a.name ? -1 : 1))
        },
    },
    created() {
        this.init()
    },
    beforeDestroy() {},
    methods: {
        async init() {
            this.inviteList = JSON.parse(window.localStorage.getItem('inviteList') || '[]')
        },
        onInput(event) {
            this.search = event.target.value
        },
        async invite(contact) {
            if (contact.done) return
            try {
                const data = await userService.sendInvite(contact.number)
                this.inviteList.push(contact.number)
                contact.done = true
                window.localStorage.setItem('inviteList', JSON.stringify(this.inviteList))
                this.$toast.success(data.msg)
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.contacts-list {
    .bottom-border {
        border-bottom: 1px solid $grey-02;
    }
    .title {
        @include f-medium;
        font-size: 24px;
        color: black;
        margin-left: 16px;
        margin-top: 12px;
    }
    .search-box {
        width: calc(100% - 32px);
        margin: 28px 16px;
        padding: 12px 16px;
        border-radius: 10px;
        border: solid 1px $grey-03;
        display: flex;
        flex-direction: row;

        input {
            margin-left: 8px;
            box-shadow: none;
            -webkit-appearance: none;
            border: none;
        }
    }
    .body {
        margin: 0 16px;

        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            .left {
                .name {
                    @include f-medium;
                    font-size: 16px;
                    color: black;
                }
                .number {
                    margin-top: 2px;
                    font-size: 12px;
                    color: $grey-07;
                }
            }
            .right {
                width: 80px;
                padding: 5px;
                text-align: center;
                border-radius: 6px;
                color: $purple-primary;
                background-color: rgba(106, 74, 255, 0.1);
                @include f-medium;
                font-size: 13px;
            }
            .complete {
                background-color: $grey-02;
                color: $grey-05;
                @include f-regular;
            }
        }
    }
}
</style>
